@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

html {
  user-select: none;
}

html,
body {
  @apply bg-white dark:bg-gray-900;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  font-weight: 300;
}

#__next {
  min-height: 100vh;
  position: relative;
  z-index: 0;
}

main {
  min-height: 100vh;
}

p:empty {
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
}

ul li p {
  margin: 0 !important;
}

.stretch-link::after {
  content: '';
  @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

*:focus {
  outline-color: @apply text-brand-400;
}

.btn-primary {
  position: relative;
  @apply relative w-full font-serif font-medium flex items-center justify-center px-4 py-3 border border-transparent text-lg rounded-md md:py-4 md:text-xl md:px-10 tracking-wide text-white bg-brand-600 transition-all duration-200;
}

.btn-primary:hover {
  @apply bg-brand-700 transition-all duration-200 rounded-md;
}

.btn-primary::after {
  content: ' ';
  position: absolute;
  @apply inset-0 shadow-lg rounded-md transition-all duration-200;
  opacity: 0;
}

.btn-primary:hover::after {
  @apply transition-all duration-200;
  opacity: 1;
}

.btn-primary:focus,
.btn-secondary:focus {
  @apply outline-none rounded-md ring-2 ring-offset-2 ring-brand-500;
}

.btn-secondary {
  position: relative;
  @apply w-full font-serif font-medium flex items-center justify-center px-8 py-3 text-base rounded-md md:py-4 md:text-lg md:px-10 tracking-wide text-gray-900 bg-gray-50 border border-gray-100 transition-all duration-200;
}

.btn-secondary::after {
  content: ' ';
  position: absolute;
  @apply inset-0 shadow-md rounded-md transition-all duration-200;
  opacity: 0;
}

.btn-secondary:hover::after {
  @apply transition-all duration-200;
  opacity: 1;
}

.btn-secondary:hover {
  @apply bg-gray-100;
}

.StripeElement {
  padding: 0 !important;
}

.booking-input {
  @apply appearance-none relative block w-full px-4 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md;
  font-size: 1rem;
  margin: 0 !important;
}

.StripeElement--focus,
.booking-input:focus {
  @apply z-10 outline-none ring-brand-500 border-2 border-brand-500 placeholder-gray-400;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.StripeElement.StripeElement--invalid {
  @apply border-red-700;
}

.DatePicker {
  @apply flex w-full !important;
  @apply max-w-lg;
  justify-content: center;
  align-items: center;
}

.DatePicker > input {
  @apply max-w-lg;
}

.DatePicker__input {
  @apply block bg-white w-full shadow-md rounded-md my-0 py-4 px-4 transition-all duration-150 ease-linear !important;
  max-width: 500px;
  font-size: 1rem !important;
  border: 0;
  outline: 0;
  margin: 0 !important;
}

#header a,
footer li a,
#social a,
#services-menu a,
#more-menu a {
  @apply outline-none;
}

#header a:focus,
footer li a:focus,
#social a:focus,
#services-menu a:focus,
#more-menu a:focus {
  @apply outline-none rounded-md ring-2 ring-offset-2 ring-brand-500;
}

/* @import '/datepicker.css'; */

.klaviyo-form-VECBdL form {
  padding: 0 !important;
  float: none !important;
}

.klaviyo-form-VECBdL form > div > div > div {
  padding: 0 !important;
}

.klaviyo-form-VECBdL input[type='text'],
.klaviyo-form-VECBdL input[type='email'] {
  @apply appearance-none rounded-none relative block w-full px-4 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md my-8;
}

.active-appt-btn {
  color: #aa8446;
  border: 4px solid #aa8446;
  font-weight: bold;
}

.gold-border-checkbox {
  border: 1px solid #aa8546 !important;
}

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  font-family: 'Cormorant';
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before,
.accordion__button[aria-expanded='true'] {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
}

[hidden] {
  display: none;
}

.accordion__panel {
  animation: fadein 0.35s ease-in;
}

.price-table {
  border-collapse: collapse;
  width: 100%;
}

.price-table-row {
  vertical-align: middle;
}

.price-table-row:nth-child(even) {
  background-color: #f2f2f2;
}

.price-table-header {
}

.price-table-data {
}

.price-table-header,
.price-table-data {
  text-align: left;
  padding: 8px;
}

.pricing-learn-more {
  color: #aa8546;
  text-decoration: underline;
}

.foundOpacity {
  opacity: 1;
}

.disabled-btn {
  pointer-events: none !important;
  opacity: 0.3;
  display: none;
}

.upText,
.downText {
  text-anchor: middle;
  font-weight: 700;
  font-size: 14px;
  fill: #03a9f4;
  letter-spacing: 0.4px;
  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
  text-rendering: optimizeSpeed;
}

.upText {
  font-size: 24px;
}

#dragger {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.downText {
  letter-spacing: -0.4px;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

q {
  quotes: '“' '”' '‘' '’';
}
q::before {
  content: '';
}
q::after {
  content: '';
}

blockquote:before {
  content: '“';
  left: -1rem;
  top: -1rem;
}

blockquote:before,
blockquote:after {
  position: absolute;
  color: #f1efe6;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

blockquote {
  font-weight: 100;
  font-size: 2rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 1.5rem 1rem 1.5rem 1rem;
  text-align: center;
}

blockquote:after {
  content: '”';
  right: -1rem;
  bottom: -1rem;
}

.prose li {
  font-size: 1rem;
}

@media not all and (min-resolution: 0.001dpcm) {
  img[loading='lazy'] {
    clip-path: inset(0.5px);
  }
}
